import { SliceZone } from "@prismicio/react"
import { Layout, SEO, sliceComponents } from "components"
import { graphql } from "gatsby";
import * as React from "react"

interface IIndexPageProps {
  data: any;
}

const IndexPage: React.FC<IIndexPageProps> = ({ data }) => {

  if (!data) {
    return null;
  }

  const doc = data.prismicPage.data;

  return (
    <Layout isHomePage>
      <SEO {...doc.body1[0].primary} />
      <SliceZone
        slices={doc.body}
        components={sliceComponents}
        defaultComponent={() => null} />
    </Layout>
  );
}

export const query = graphql`
  {
    prismicPage {
      data {
        body1 {
          ... on PrismicPageDataBody1GeneralCard {
            slice_type
            primary {
              description
              title
            }
          }
        }
        body {
          ... on PrismicPageDataBodyClientTestimonials {
            slice_type
          }
          ... on PrismicPageDataBodyTeambleVideo {
            slice_type
          }
          ... on PrismicPageDataBodyQuoteSection {
            slice_type
          }
          ... on PrismicPageDataBodyHowItWorksSection {
            slice_type
          }
          ... on PrismicPageDataBodySlackMsTeamsSection {
            slice_type
          }
          ...VideoSectionData
          ...ClientTestimonialsData
          ...QuoteSectionData
          ...HowItWorksSectionData
          ...DoItAllSectionData
        }
      }
    }
  }
`;


export default IndexPage;
